



























































































































































































































































































































































































































































import { Vue, Component, Emit, Prop, Watch, VModel } from 'vue-property-decorator';
import { VueMaskFilter } from 'v-mask';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { LoaderComponent } from 'vue-loading-overlay';
import { cloneDeep, sortBy } from 'lodash';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import Field from '@/components/field.vue';
import ProspectService from '@/services/crm/prospect.service';
import ProspectModel from '@/models/crm/prospect.model';
import { FormHelper } from '@/utils/helpers/form-helper';
import StateModel from '@/models/state.model';
import CityModel from '@/models/city.model';
import LocalizationService from '@/services/localization.service';
import { ProspectStatusEnum } from '@/enums/crm/prospect-status.enum';
import RepresentativeModel from '@/models/crm/representative.model';
import LeadModel from '@/models/crm/lead.model';
import ClientModel from '@/models/crm/client.model';
import SintegraProspectModel from '@/models/sintegra-prospect.model';
import RepresentativeService from '@/services/crm/representative.service';
import LeadService from '@/services/crm/lead.service';
import UserModel from '@/models/user.model';
import ActivityService from '@/services/crm/activity.service';
import SettingsService from '@/services/crm/settings.service';
import SintegraService from '@/services/sintegra.service';
import SettingsModel from '@/models/crm/settings.model';
import ContactChannelService from '@/services/crm/contact-channel.service';
import ContactChannelModel from '@/models/crm/contact-channel.model';
import ClientService from '@/services/crm/client.service';
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import ContactModel from '@/models/crm/contact.model';
import CrmExistingContactsView from './existing-contacts-view.vue';
import ContactService from '@/services/crm/contact.service';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import { eventBus } from '@/utils/eventBus';

type GroupedRepresentatives = {
  header: string | null;
  representative: RepresentativeModel | null;
  name: string | null;
  code: string | null;
  group: string | null;
  divider: boolean | null;
};

@Component({
  components: {
    Field,
    ConfirmationDialog,
    CrmExistingContactsView,
  },
})
export default class CrmProspectForm extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.SintegraService)
  private sintegraService!: SintegraService;

  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.LocalizationService)
  private localizationService!: LocalizationService;

  @inject(InjectionIdEnum.CrmRepresentativeService)
  private representativeService!: RepresentativeService;

  @inject(InjectionIdEnum.CrmLeadService)
  private leadService!: LeadService;

  @inject(InjectionIdEnum.CrmContactChannelService)
  private contactChannelService!: ContactChannelService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @VModel()
  hasExistingContacts!: boolean;

  @Prop()
  id!: number;

  @Prop({ required: true })
  client!: ClientModel;

  btnSintegraAtivo = false;

  formIsValid = true;

  model: ProspectModel = plainToClass(ProspectModel, {
    codProspect: null,
    uuid: null,
    dataAtualizacao: null,
    codEmpresa: null,
    codRepres: null,
    nomeRepres: null,
    dataCadastro: null,
    cnpj: null,
    razaoSocial: null,
    fantasia: null,
    email: null,
    emailNFE: null,
    contato: null,
    inscricaoEstadual: null,
    suframa: null,
    consumidorFinal: null,
    ddi: '55',
    telefone: null,
    celular: null,
    cep: null,
    endBairro: null,
    endereco: null,
    endNumero: null,
    endComplemento: null,
    cidade: null,
    estado: null,
    observacao: null,
    situacao: ProspectStatusEnum.Active,
    idProcesso: null,
    situacaoProcesso: null,
    facebook: null,
    instagram: null,
    origemSintegra: false,
    origemCadastro: 1,
  });

  rules: IKeyValue<CallableFunction[]> = {
    email: [ValidationRules.email],
  };

  telephoneMaskActive = false;

  celularMaskActive = false;

  cnpjMaskActive = false;

  stateOptions: StateModel[] = [];

  cityOptions: CityModel[] = [];

  existingContacts!: ContactModel[];

  existingTelephones!: ContactModel[];

  cityLoading = false;

  statusOptions = [
    {
      value: ProspectStatusEnum.Active,
      name: `${this.$t('crm.prospectForm.status.active')}`,
    },
    {
      value: ProspectStatusEnum.Converted,
      name: `${this.$t('crm.prospectForm.status.converted')}`,
    },
    {
      value: ProspectStatusEnum.Inactive,
      name: `${this.$t('crm.prospectForm.status.inactive')}`,
    },
  ];

  representativeOptions: RepresentativeModel[] = [];

  groupedRepresentatives: GroupedRepresentatives[] = [];

  representativeSearch = '';

  leadOptions: LeadModel[] = [];

  contactChannelOptions: ContactChannelModel[] = [];

  attendantOptions: UserModel[] = [];

  settings: SettingsModel | null = null;

  editingRepresentative = false;

  dialogConfig: IKeyValue<IDialogConfig> = {
    confirmation: {
      message: '',
      color: '',
      show: false,
      textConfirm: '',
      onlyWarning: false,
      onChoice: () => {},
    },
    existingContacts: {
      show: false,
    },
    existingTelephones: {
      show: false,
    },
  };

  cnpjIsMandatory = false;

  disableStateInscription = false;

  disableLegalName = false;

  celularChanged = false;

  telefoneChanged = false;

  celular = '';

  telefone = '';

  telefoneAcceptedDuplicate = '';

  celularAcceptedDuplicate = '';

  clickedFromSave = false;

  isSaving = false;

  async created(): Promise<void> {
    this.settingsService.getSettings().then((settings) => {
      if (settings.flagIntegracaoSintegraAtivo) {
        this.btnSintegraAtivo = true;
      }
    });
  }

  async mounted(): Promise<void> {
    const loader = this.$loading.show();
    this.settings = await this.settingsService.getSettings();

    try {
      await this.loadOptions();

      this.rules.situacao = [ValidationRules.required];
      this.rules.razaoSocial = [ValidationRules.required];
      if (this.settings.flagTornarCnpjProspectObrigatorio) {
        this.rules.cnpj = [ValidationRules.required];
        this.cnpjIsMandatory = true;
      }
      if (this.settings.flagEmailProspectObrigatorio) {
        this.rules.email = [ValidationRules.email, ValidationRules.required];
      }
      if (this.settings.flagTelefoneProspectObrigatorio) {
        this.rules.telefone = [ValidationRules.phone, ValidationRules.required];
        this.rules.celular = [ValidationRules.phone, ValidationRules.required];
      }

      if (this.id) {
        await this.loadModel();

        if (this.model?.cidade) {
          this.cityOptions = sortBy(await this.localizationService.getCities(this.model.cidade?.siglaUF), 'nome');

          const state = this.stateOptions.find((x) => x.sigla === this.model.cidade.siglaUF);
          this.model.estado = state as StateModel;

          const city = this.cityOptions.find((x) => x.codIBGE === this.model.cidade.codIBGE);
          this.model.cidade = city as CityModel;

          this.onCityChange();
        }

        if (this.model.codRepres) {
          this.model.representante = this.representativeOptions.find(
            (x) => x.codigo === this.model.codRepres,
          ) as RepresentativeModel;
        }
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  onEditRepresentative(): void {
    this.editingRepresentative = true;
  }

  onRepresentativeChange(groupedRepres: GroupedRepresentatives): void {
    if (groupedRepres.representative !== null) {
      this.model.representante = groupedRepres.representative;
    }
  }

  @Watch('representativeSearch')
  onCityChange(): void {
    this.groupedRepresentatives = [];
    if (this.model.cidade) {
      this.groupedRepresentatives.push({
        header: this.$t('crm.prospectForm.cityRepresentatives').toString(),
        representative: null,
        name: null,
        code: null,
        group: null,
        divider: null,
      });
      this.representativeOptions.forEach((repres) => {
        if (repres.municipios && repres.municipios.includes(this.model.cidade.codIBGE.substring(0, 7))) {
          if (
            !this.representativeSearch
            || this.representativeSearch.length < 3
            || (this.representativeSearch.length >= 3
              && (repres.codigo.includes(this.representativeSearch)
                || repres.nome.toLowerCase().includes(this.representativeSearch.toLowerCase())))
          ) {
            this.groupedRepresentatives.push({
              header: null,
              representative: repres,
              name: repres.nome,
              code: repres.codigo,
              group: this.$t('crm.prospectForm.cityRepresentatives').toString(),
              divider: null,
            });
          }
        }
      });
      this.groupedRepresentatives.push({
        header: null,
        representative: null,
        name: null,
        code: null,
        group: null,
        divider: true,
      });
      this.groupedRepresentatives.push({
        header: this.$t('crm.prospectForm.otherRepresentatives').toString(),
        representative: null,
        name: null,
        code: null,
        group: null,
        divider: null,
      });
      this.representativeOptions.forEach((repres) => {
        if (repres.municipios && !repres.municipios.includes(this.model.cidade.codIBGE.substring(0, 7))) {
          if (
            !this.representativeSearch
            || this.representativeSearch.length < 3
            || (this.representativeSearch.length >= 3
              && (repres.codigo.includes(this.representativeSearch)
                || repres.nome.toLowerCase().includes(this.representativeSearch.toLowerCase())))
          ) {
            this.groupedRepresentatives.push({
              header: null,
              representative: repres,
              name: repres.nome,
              code: repres.codigo,
              group: this.$t('crm.prospectForm.otherRepresentatives').toString(),
              divider: null,
            });
          }
        }
      });
    }
  }

  async onConsultSintegra(): Promise<void> {
    let res!: SintegraProspectModel;
    if (this.model.cnpj && this.model.estado) {
      const loader = this.$loading.show();
      try {
        const retSintegra = await this.sintegraService.getDataCrm(this.model.cnpj, this.model.estado.sigla);
        if (retSintegra && retSintegra.erro) {
          this.$notify.error(retSintegra.erro);
        } else {
          res = retSintegra;
          if (this.settings?.flagCongelaPreenchimentoSintegra) {
            if (res.inscricaoEstadual) {
              this.disableStateInscription = true;
            }
            if (res.razaoSocial) {
              this.disableLegalName = true;
            }
          }
          this.model.origemSintegra = true;
          this.model.situacao = res.situacao !== '' ? Number(res.situacao) : 1;
          this.model.fantasia = res.nomeFantasia;
          this.model.razaoSocial = res.razaoSocial;
          this.model.inscricaoEstadual = res.inscricaoEstadual === 'null' ? '' : res.inscricaoEstadual;
          const repres = this.representativeOptions.find((rep) => rep.codigo === res.codRepres);
          if (repres) {
            this.model.representante = repres;
          }
          this.model.suframa = res.suframa;
          this.model.observacao = res.observacoes;
          if (!this.settings?.flagNaoPreencheTelefoneEmailProspectSintegra) {
            if (res.telefone !== null) {
              this.telefone = res.telefone;

              const telefoneFormatado = VueMaskFilter(res.telefone, this.telephoneMask);
              this.telefone = telefoneFormatado;
              this.model.telefone = telefoneFormatado;
            }
            this.model.email = res.email;
          }
          this.model.facebook = res.facebook;
          this.model.instagram = res.instagram;
          this.model.endereco = res.logradouro;
          this.model.endNumero = res.numero;
          this.model.endComplemento = res.complemento;
          this.model.cep = VueMaskFilter(res.cep, '##.###-###');
          this.model.endBairro = res.bairro;
          const cidade = this.cityOptions.find((city) => city.codIBGE === res.codIBGE);
          if (cidade) {
            this.model.cidade = cidade;
          }
        }
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
      this.onCityChange();
    } else {
      this.$notify.error(this.$t('crm.prospectForm.informCnpjAndStateSintegra').toString());
    }
  }

  async onConsultCep(): Promise<void> {
    if (this.model.cep && this.model.cep.length === 10) {
      try {
        const result = await this.prospectService.getAddressByCep(this.model);
        this.model.estado = { sigla: result.cidade.siglaUF, nome: result.cidade.nomeUF };
        this.model.cidade = {
          codIBGE: result.cidade.codIBGE,
          nome: result.cidade.nome,
          siglaUF: result.cidade.siglaUF,
          nomeUF: result.cidade.nomeUF,
          pais: result.cidade.pais,
        };
        this.model.endereco = result.endereco;
        this.model.endNumero = result.endNumero;
        this.model.endBairro = result.endBairro;
        this.model.endComplemento = result.endComplemento;
        this.onStateChange(this.model.cidade.siglaUF, false);
        this.onCityChange();
      } catch (err) {
        this.$notify.error(err);
      }
    } else {
      this.$notify.error(
        `${this.$t('crm.prospectForm.informCep', {
          completo: !this.model.cep || (this.model.cep && this.model.cep === '') ? '' : 'completo',
        })}`,
      );
    }
  }

  async onStateChange(uf: string, clearCity = true): Promise<void> {
    this.cityOptions = [];
    if (clearCity) {
      this.model.cidade = null as unknown as CityModel;
    }

    if (!uf) {
      return;
    }

    this.cityLoading = true;
    try {
      this.cityOptions = sortBy(await this.localizationService.getCities(uf), 'nome');
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      this.cityLoading = false;
    }
  }

  async onVerifyContact(isSavingValidation = false): Promise<void> {
    this.hasExistingContacts = false;
    if (this.isWhatsappBlank || this.celularAcceptedDuplicate) {
      this.celularChanged = false;
      return;
    }

    if (!this.isWhatsappBlank && !this.isModelWhatsappBlank
      && (this.celular.replace(/\D/g, '') === this.model.celular.replace(/\D/g, ''))) {
      this.celularChanged = false;
      return;
    }

    try {
      this.existingContacts = await this.contactService.verifyWaIdContacts(
        null,
        this.celular,
        ClientTypeEnum.Prospect,
        null,
        false,
        false,
      );

      if (this.existingContacts === undefined) {
        this.existingContacts = [];
      }

      const prospectContacts: ContactModel[] = await this.contactService.verifyWaIdContactsProspect(
        this.model.clientModel.prospectId,
        this.celular,
        false,
        false,
      );

      prospectContacts.forEach((contact) => {
        if (this.existingContacts.find((c) => c.cnpj === contact.cnpj || contact.idProspect === c.idProspect)) return;
        this.existingContacts.push(contact);
      });

      const clientContacts: ContactModel[] = await this.contactService.verifyWaIdContactsClient(
        undefined,
        this.celular,
        false,
        false,
      );

      clientContacts.forEach((contact) => {
        if (this.existingContacts.find((c) => c.cnpj === contact.cnpj || contact.idProspect === c.idProspect)) return;
        this.existingContacts.push(contact);
      });

      if (this.existingContacts && this.existingContacts.length !== 0) {
        if (!this.dialogConfig.existingContacts.show) {
          this.toggleDialogExistingContacts(isSavingValidation);
        }
        if (isSavingValidation) {
          this.isSaving = false;
        }
      } else {
        this.celularChanged = false;
        if (this.clickedFromSave) {
          this.clickedFromSave = false;
          this.onSave();
        }
        if (isSavingValidation) {
          this.isSaving = false;
        }
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  async onVerifyTelephone(isSavingValidation = false): Promise<void> {
    this.hasExistingContacts = false;

    if (this.isTelephoneBlank || this.telefone === this.telefoneAcceptedDuplicate) {
      this.telefoneChanged = false;
      return;
    }

    if (!this.isTelephoneBlank && !this.isModelTelephoneBlank
      && (this.telefone.replace(/\D/g, '') === this.model.telefone.replace(/\D/g, ''))) {
      this.telefoneChanged = false;
      return;
    }

    try {
      this.existingTelephones = await this.contactService.verifyWaIdContacts(
        null,
        this.telefone,
        ClientTypeEnum.Prospect,
        null,
        false,
        true,
      );

      if (this.existingTelephones === undefined) {
        this.existingTelephones = [];
      }

      const clientContacts: ContactModel[] = await this.contactService.verifyWaIdContactsClient(
        this.model.clientModel.prospectId,
        this.telefone,
        false,
        true,
      );

      clientContacts.forEach((contact) => {
        if (this.existingTelephones.find((c) => c.cnpj === contact.cnpj || c.idProspect === contact.idProspect)) return;
        this.existingTelephones.push(contact);
      });

      const prospectContacts: ContactModel[] = await this.contactService.verifyWaIdContactsProspect(
        this.model.clientModel.prospectId,
        this.telefone,
        false,
        true,
      );

      prospectContacts.forEach((contact) => {
        if (this.existingTelephones.find((c) => c.cnpj === contact.cnpj || c.idProspect === contact.idProspect)) return;
        this.existingTelephones.push(contact);
      });

      if (this.existingTelephones && this.existingTelephones.length !== 0) {
        if (!this.dialogConfig.existingTelephones.show) {
          this.toggleDialogExistingTelephoneContacts(isSavingValidation);
        }
        if (isSavingValidation) {
          this.isSaving = false;
        }
      } else {
        this.telefoneChanged = false;
        if (this.clickedFromSave) {
          this.clickedFromSave = false;
          this.onSave();
        }
        if (isSavingValidation) {
          this.isSaving = false;
        }
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  async onSave(): Promise<void> {
    if ((this.celular !== null && this.model.celular && this.model.celular !== null)
        && (this.celular.replace(/\D/g, '') === this.model.celular.replace(/\D/g, ''))) {
      this.celularChanged = false;
    }

    if ((this.telefone !== null && this.model.telefone && this.model.telefone !== null)
        && (this.telefone.replace(/\D/g, '') === this.model.telefone.replace(/\D/g, ''))) {
      this.telefoneChanged = false;
    }

    if (this.celularChanged) {
      if (this.celular === this.celularAcceptedDuplicate && this.celularAcceptedDuplicate !== '') {
        this.celularChanged = false;
        this.onSave();
      } else {
        this.clickedFromSave = true;
        await this.onVerifyContact(true);
      }
    } else if (this.telefoneChanged) {
      if (this.telefone === this.telefoneAcceptedDuplicate && this.telefoneAcceptedDuplicate !== '') {
        this.telefoneChanged = false;
        this.onSave();
      } else {
        this.clickedFromSave = true;
        await this.onVerifyTelephone(true);
      }
    } else {
      if (this.model.situacao === ProspectStatusEnum.Converted) {
        if (this.settings?.flagConverteProspectIGS && this.settings?.flagIntegraClienteAposConversaoProspect) {
          this.$notify.error(this.$t('crm.prospectForm.optionConverted'));
          this.model.situacao = ProspectStatusEnum.Active;
          return;
        }
      }

      if (FormHelper.validate(this.$refs.form as Vue) && !this.celularChanged) {
        const loader = this.setBusyLoader();
        try {
          this.saveProspect();
        } catch (err) {
          this.$notify.error(err && (err as Error).message);
        } finally {
          loader.hide();
        }
      } else if (!this.telefone && this.settings?.flagTelefoneProspectObrigatorio) {
        this.$notify.warn(this.$t('crm.prospectForm.warn.phoneIsRequired'));
      } else if (!this.model.email && this.settings?.flagEmailProspectObrigatorio) {
        this.$notify.warn(this.$t('crm.prospectForm.warn.emailIsRequired'));
      } else {
        if (this?.client?.prospectId != null) {
          this.$notify.warn('Preencha todos os campos obrigatórios para editar o cadastro.');
          return;
        }
        this.$notify.warn('Preencha todos os campos obrigatórios para concluir o cadastros.');
      }
    }
  }

  async saveProspect(): Promise<void> {
    const model = cloneDeep(this.model);

    model.cnpj = (model.cnpj !== null && model.cnpj ? model.cnpj : '').replace(/\D/g, '');
    model.telefone = (this.telefone || '').replace(/\D/g, '');
    model.celular = (this.celular || '').replace(/\D/g, '');
    model.dataAtualizacao = new Date();
    let result;
    try {
      result = await this.prospectService.create(model, !!this.model.codProspect);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }

    this.model.codProspect = result.toString();

    this.$notify.success(this.$t('crm.prospectForm.successfullySave'));
    this.$emit('complete', this.model);
    eventBus.$emit('edit-prospect-complete', this.model);
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  onCancelContact(isForCelular = true): void {
    if (isForCelular) {
      this.existingContacts = [];
      this.celularChanged = false;
      this.celular = '';
      this.toggleDialogExistingContacts();
    } else {
      this.existingTelephones = [];
      this.telefone = '';
      this.toggleDialogExistingTelephoneContacts();
    }
  }

  get firstRowCols(): number {
    return this.btnSintegraAtivo ? 4 : 3;
  }

  get cellphoneCols(): number {
    return this.btnSintegraAtivo ? 3 : 4;
  }

  get socialsCols(): number {
    return this.btnSintegraAtivo ? 4 : 6;
  }

  get telephoneMask(): string | boolean {
    const parsedNumber = (this.telefone || '').replace(/\D/g, '');
    if (parsedNumber.length > 10) {
      return '(##) #####-####';
    }
    if (parsedNumber !== '') {
      return '(##) ####-####';
    }
    return '';
  }

  get celularMask(): string | boolean {
    const parsedNumber = (this.celular || '').replace(/\D/g, '');
    if (parsedNumber.length > 10) {
      return '(##) #####-####';
    }
    if (parsedNumber !== '') {
      return '(##) ####-####';
    }
    return '';
  }

  get registrationUser(): string {
    if (this.model.inclusaoUsuarioId) {
      const user = this.attendantOptions.find((x) => x.id === this.model.inclusaoUsuarioId);

      if (user) {
        return `${user.nome} - `;
      }
    }
    return '';
  }

  get changeUser(): string {
    if (this.model.alteracaoUsuarioId) {
      const user = this.attendantOptions.find((x) => x.id === this.model.alteracaoUsuarioId);

      if (user) {
        return `${user.nome} - `;
      }
    }
    return '';
  }

  get mapMarkerStyle(): string {
    const repres = this.representativeOptions.find((rep) => rep.codigo === this.model.codRepres);
    return repres?.municipios.includes(this.model.cidade.codIBGE.substring(0, 7))
      ? 'mdi-map-marker'
      : 'mdi-map-marker-off';
  }

  get isPhoneRequired(): boolean {
    return this.settings?.flagTelefoneProspectObrigatorio || false;
  }

  get isEmailRequired(): boolean {
    return this.settings?.flagEmailProspectObrigatorio || false;
  }

  private async loadOptions(): Promise<void> {
    await Promise.all([
      this.loadRepresentatives(),
      this.loadStates(),
      this.loadLeads(),
      this.loadContactChannels(),
      this.loadAttendants(),
    ]);
  }

  private async loadModel(): Promise<void> {
    const result = await this.prospectService.get(this.id);

    this.model = plainToClass(ProspectModel, result);
    this.telefone = this.model.telefone;
    this.celular = this.model.celular;

    if (this.model.origemSintegra && this.settings?.flagCongelaPreenchimentoSintegra) {
      if (this.model.inscricaoEstadual) {
        this.disableStateInscription = true;
      }
      if (this.model.razaoSocial) {
        this.disableLegalName = true;
      }
    }

    setTimeout(() => {
      this.cnpjMaskActive = true;
      this.telephoneMaskActive = true;
      this.celularMaskActive = true;
    }, 150);
  }

  private async loadStates(): Promise<void> {
    this.stateOptions = sortBy(await this.localizationService.getStates(), 'nome');
  }

  private async loadRepresentatives(): Promise<void> {
    this.representativeOptions = await this.representativeService.getRepresentatives();
  }

  private async loadLeads(): Promise<void> {
    this.leadOptions = await this.leadService.getLeads();
  }

  private async loadContactChannels(): Promise<void> {
    this.contactChannelOptions = await this.contactChannelService.getContactChannels();
  }

  private async loadAttendants(): Promise<void> {
    this.attendantOptions = await this.activityService.getAttendants();
  }

  toggleDialogExistingContacts(isSavingValidation = false): void {
    if (!this.isSaving || (this.isSaving && isSavingValidation)) {
      this.dialogConfig.existingContacts.show = !this.dialogConfig.existingContacts.show;
      this.hasExistingContacts = !this.hasExistingContacts;
    }
  }

  toggleDialogExistingTelephoneContacts(isSavingValidation = false): void {
    if (!this.isSaving || (this.isSaving && isSavingValidation)) {
      this.dialogConfig.existingTelephones.show = !this.dialogConfig.existingTelephones.show;
      this.hasExistingContacts = !this.hasExistingContacts;
    }
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.prospectFormContainer,
      canCancel: false,
    });
  }

  async verificaCnpjExisteEmCliente(): Promise<void> {
    const loader = this.setBusyLoader();
    try {
      let client: ClientModel | null = null;
      if ((this.model.cnpj && this.model.cnpj !== '')
        && (this.model.cnpj.replace(/\D/g, '').length === 11
        || this.model.cnpj.replace(/\D/g, '').length === 14)) {
        client = await this.clientService.getSummary(
          (this.model.cnpj).replace(/\D/g, ''),
          ClientTypeEnum.Client, true,
        );
      }
      if (client !== null) {
        if (client.tipo != null && client.tipo.toLowerCase() !== ClientTypeEnum.Prospect) {
          this.dialogConfig.confirmation.show = true;
          this.dialogConfig.confirmation.color = 'green';
          this.dialogConfig.confirmation.textConfirm = this.$t('global.ok');
          this.dialogConfig.confirmation.onlyWarning = true;
          this.dialogConfig.confirmation.message = this.$t('crm.prospectForm.dialog.cnpjExistingClient',
            { nomeCliente: client.nome, codCliente: client.codCliente }).toString();
          this.dialogConfig.confirmation.onChoice = (accept: boolean) => {
            if (accept) {
              this.dialogConfig.confirmation.show = false;
              this.model.cnpj = '';
            }
          };
        }
      } else {
        const clientIgs = await this.clientService.getIgsClient((this.model.cnpj).replace(/\D/g, ''));
        if (clientIgs !== null) {
          this.dialogConfig.confirmation.show = true;
          this.dialogConfig.confirmation.color = 'green';
          this.dialogConfig.confirmation.textConfirm = this.$t('global.ok');
          this.dialogConfig.confirmation.onlyWarning = true;
          this.dialogConfig.confirmation.message = this.$t('crm.prospectForm.dialog.cnpjExistingClient',
            { nomeCliente: clientIgs.nome, codCliente: clientIgs.cnpjCpf }).toString();
          this.dialogConfig.confirmation.onChoice = (accept: boolean) => {
            if (accept) {
              this.dialogConfig.confirmation.show = false;
              this.model.cnpj = '';
            }
          };
        }
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  get isTelephoneBlank(): boolean {
    return this.telefone === undefined || this.telefone === '' || this.telefone === null;
  }

  get isWhatsappBlank(): boolean {
    return this.celular === undefined || this.celular === '' || this.celular === null;
  }

  get isModelWhatsappBlank(): boolean {
    return this.model.celular === undefined || this.model.celular === '' || this.model.celular === null;
  }

  get isModelTelephoneBlank(): boolean {
    return this.model.telefone === undefined || this.model.telefone === '' || this.model.telefone === null;
  }
}
